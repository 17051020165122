const { LIVE_STAGE } = require('@/constants/room');

const config = {
    //缓存key 相关
    USER_DATA_KEY: 'user_data_key',
    SET_SDK_CACHE: 'SET_SDK_CACHE',
    //观众用户是否填下资料 0 未填写  1-已填写
    USER_SHOW_FILTER:"user_show_filter",
    //页面路由存储
    ROOM_LAST_PAGE:"room_last_page",
    ROOM_LIVE_STAGE:"room_live_stage",
    ROOM_USER_VOTE_COMMET:"room_user_vote_commet",
    // HOT_ONLINE:"hot_online",

    //常量定义相关
    /**
     * 户权限相关定义
     * 用户角色 startv:主播 | watch:观众 | major|管理
     */
    USER_ROLE_STARTV:"startv",
    USER_ROLE_WATCH:"watch",
    USER_ROLE_MAJOR:"major",
    CONFIGLIST:{
      SESSION_WEB_TOKENINFO:"authToken",
      SESSION_WEB_AESTOKEN:"aestoken",
    },
    //麦克风
    PUSHER_MICROPHONE_KEY: "pusher_microphone_key",
    //扬声器
    PUSHER_SPEAKER_KEY: "pusher_speaker_key",
    //摄像头
    PUSHER_CAMERA_KEY: "pusher_camera_key",

    /**
    * 房间状态：
    * no_live- no_live-未开播 live_ing-直播中 forbidden-房间封禁 live_pause-直播暂停 live_over-直播结束
    */
    ROOM_STATUS_MSG:{
      'no_live': {'name':"未开播", 'msg': '该房间未开播'},
      'live_ing': {'name':"直播中", 'msg': '直播中'},
      'forbidden': {'name':"未开播", 'msg':'该房间未开播'},
      'live_pause': {'name':"未开播", 'msg':'该房间未开播'},
      'live_over': {'name':"未开播", 'msg':'该房间未开播'},
    },

    OBS_STATIC_URL: '',
    WEB_VERSION: '2.0.0',
    // 手机号，区号
    PHONE_AREA_CODE: '86:',
    // 平台，1-安卓 2-ios 3-web
    PLATFORM: 3,
    // 语言
    LANGUAGE: 'zh',
    //本地debug模式
    WECHAT_OPEN_APP_DEBUG: true,
    //特殊页面，不需要切换页头样式
    SPECIALPAGE: '',
    //公共字符长度配置
    strLengthThirty: 90,
    strLengthTen: 30,
    strLengthOneThousand: 3000,
    strLengthThreeThousand: 6000,
};

module.exports = config;
