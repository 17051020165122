<!--
 * @Description: 移动端页面header
 * @Date: 2021-10-31 15:59:54
 * @LastEditTime: 2022-02-15 10:58:44
-->
<template lang="pug">
  div.player-title-container
    div.left-container
      div.player-info
        div.logo-container
          //- img.logo(:src="logo")
          div.info-container(style={ color :'#D9DADB',height:'100%'})
            //- div.info-my(style={fontSize: '14px',color:'#fff',marginTop : '11px', marginBottom : '5px'}) {{msg}}
            div(style={display: 'flex'}).right-container
              div(style={textAlign:'left'})
              //- img(src="@/assets/img/zhibo_icon.png", alt="")
              //- span.info-span(style={marginLeft:'10px',color:'#000'}) {{"当前直播："}}
              //- comp-user-info(style={marginLeft:'20px'})
        //- div.player-title
        //-   span {{ roomName }}
      //- 线路选择
      //- comp-line-check
      //- div.right-container
      //- comp-layout
      //- comp-language(pageName="player")
      //- comp-exit
</template>

<script>
import compUserInfo from '@/components/pusher/comp-header/comp-user-info.vue';//直播老师userInfo
import compLayout from './comp-layout';
import compLanguage from './comp-language';
// import compUserInfo from './comp-user-info';//当前用户userInfo
import compLineCheck from '../comp-player/stream-control/comp-line-check';
import compExit from './comp-exit';
import avatar from '@/assets/img/avatar.png';
import logo from '@/assets/img/logo.png';
import { mapState } from 'vuex';
export default {
  name: 'compHeader',
  data() {
    return {
      logo,
      avatar,
      msg: "",
    };
  },
  components: {
    compLayout,
    compLanguage,
    compUserInfo,
    compLineCheck,
    compExit,
  },
  computed: {
    ...mapState({
      roomName: 'roomName',
      userInfo: 'userInfo',
      roomInfo: 'roomInfo',
    }),
  },
  created(){
     console.log(1111,this.roomInfo);
     this.msg=this.roomInfo.roomName
  },
  methods: {
  },
};
</script>

<style lang="stylus" scoped>
.player-title-container
  width 100%
  height 100%
  position relative
  display flex
  justify-content space-between
  padding 20px 16px 10px
  .left-container

    width 100%
    .player-info
      width 100%
      height 100%
      display flex
      align-items center
      min-width 140px
      max-width 180px
      height 40px
      background-color rgba(223,223,223,0.05)
      border-radius 24px
      padding-right 10px
      margin-bottom 10px

      .logo-container



        display flex
        justify-content center
        align-items center

      .logo
        width 4.4rem
        height 3rem
      .player-title
        font-weight medium
        color $fontColor
        font-size 12px
  .right-container
    height 100%
    display flex
    align-items flex-start
    & > :not(:first-child)
      margin-left 16px
</style>
