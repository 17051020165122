<template>
  <div>
    <el-dialog title="温馨提示" :close-on-click-modal="false" :visible.sync="dialogshows" width="28%" :modal="false"
      v-if="!$isMobile">
      <span style="font-size: 16px;">直播开始，立即前往</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goplay">进入直播间</el-button>
      </span>
    </el-dialog>

    <el-dialog title="温馨提示" :close-on-click-modal="false" :visible.sync="dialogshows" width="70%" :modal="false"
      v-if="$isMobile">
      <span style="font-size: 16px;">直播开始，立即前往</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goplay">进入直播间</el-button>
      </span>
    </el-dialog>
  </div>


</template>

<script>
import StorageUtils from '@/putils/StorageUtils';
import { ROOM_LAST_PAGE } from "@/constants/localKeys"
import liveConfigInfo from '@/config/liveConfigInfo';
import liveRoomConfig from '@/config/liveRoomConfig';
import { mapState } from 'vuex';
export default {
  mixins: [liveConfigInfo, liveRoomConfig],
  props: {
    dialogshows: '',
  },
  data() {
    return {
      //dialogshow: true,
      playback: {
        roomNo: "",
        userId: ""
      },
      initTime: 5
    };
  },
  computed: {
    ...mapState({
      userInfo: 'userInfo',
      roomInfo: 'roomInfo',
    }),
  },
  async created() {
    let { roomId, userId } = this.$route.query;
    this.playback.roomNo = roomId
    this.playback.userId = userId
    await this.getCacheRoomInfo(roomId, "")
  },
  methods: {
    async goplay() {
      this.dialogshows = false
      let val = this.$route;
      let dres = await this.getRoodDetail({ roomNo: this.roomInfo.roomId })
      this.setStateRoomData(dres);
      this.setUserLoacData(this.userInfo.userId, dres);
      this.$EventBus.$emit("timGroupBack", val.query.roomId.toString()+'_playback')
      // console.log(dres);
      StorageUtils.setSessionCache(ROOM_LAST_PAGE, { roomId: val.query.roomId }, { path: val.path, query: val.query })
      // StorageUtils.delRoomtim(this.userInfo.userId)
        this.$router.push({
         path: 'player', query: {
          userId: this.playback.userId,
          roomId: this.playback.roomNo,
          isTepy:true
        },
      });
    },
  }
};
</script>

<style scoped>
/deep/ .el-dialog__header {
  text-align: initial;
  border-bottom: 1px solid #e0e0e0;
}

/deep/ .el-dialog__footer {
  text-align: center;
}

/deep/ button {
  border-radius: 20px;
}
</style>