import { render, staticRenderFns } from "./comp-exit.vue?vue&type=template&id=48bd0326&scoped=true&lang=pug&"
import script from "./comp-exit.vue?vue&type=script&lang=js&"
export * from "./comp-exit.vue?vue&type=script&lang=js&"
import style0 from "./comp-exit.vue?vue&type=style&index=0&id=48bd0326&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48bd0326",
  null
  
)

/* custom blocks */
import block0 from "./comp-exit.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports