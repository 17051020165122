export enum LAYOUT {
  NINE_EQUAL_POINTS = 'nine_equal_points',
  TWENTY_FIVE_EQUAL_POINTS = 'twenty_five_equal_points',
  RIGHT_SIDE_LIST = 'right_side_list',
  TOP_SIDE_LIST = 'top_side_list',
  LARGE_SMALL_WINDOW = 'large_small_window',
};

export enum SettingMode {
  SIMPLE = 'simple',
  DETAIL = 'detail',
}

/**
 * 用户角色
 * startv:主播 | watch:观众 | major|管理
 */
export enum UserRoles {
  USER_ROLE_STARTV = "startv",
  USER_ROLE_WATCH = "watch",
  USER_ROLE_MAJOR = "major",
}

/**
 * 限制类型：
 * no_filter-没有限制； register_filter-登记观看 visitor_filter-游客观看
 */
export enum RoomFilterTypes {
  ROOM_NO_FILTER = "no_filter",
  ROOM_REGISTER_FILTER = "register_filter",
  ROOM_VISITOR_FILTER = "visitor_filter",
}

/**
 * 房间状态：
 * no_live-no_live-未开播 live_ing-直播中 forbidden-房间封禁 live_pause-直播暂停 live_over-直播结束
 */
export enum RoomStatus {
  ROOM_NO_LIVE = "no_live",
  ROOM_LIVE_ING = "live_ing",
  ROOM_FORBIDDEN = "forbidden",
  ROOM_LIVE_PAUSE = "live_pause",
  ROOM_LIVE_OVER = "live_over",
}

/**
 * 直播间类型： meeting-多人会议； live-单人直播； record-录播 third_push-第三代推流
 */
export enum RoomType {
  ROOM_MEETING = "meeting",
  ROOM_LIVE = "live",
  ROOM_RECORD = "record",
  ROOM_THIRD_PUSH = "third_push",
}

/**
 * 游客观看开关限制：
 * switch_close-关闭；
 * switch_open-开启
 */
export enum  VisitorSwitch{
  ROOM_SWITCH_CLOSE="switch_close",
  ROOM_SWITCH_OPEN="switch_open"
}
