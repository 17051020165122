<template>
  <div class="exan-container">
    <div class="header" v-if="list.length===0">
       暂无数据
     </div>
    <div v-else v-for="(item, index) in list" :key="index" style="padding: 10px;">
      <div style="display: flex;align-items: center;justify-content: space-between;width: 100%;">
        <p style="display: flex;align-items: center;">
          <img style="width: 30px;border-radius: 20px;margin-right: 5px;" src="@/assets/img/avatars.png" alt="">
          <span>场控</span>
        </p>
        <p style="font-size: 13px;color: #c7c4c4;">{{ item.createDateTime }}</p>
      </div>
      <p class="title">{{ item.msgDesc }}</p>
      <div class="content" v-for="(i, j) in item.appendixList" :key="j">
        <div v-if="i.type == 'jpg' || i.type == 'png' || i.type == 'jpeg' || i.type == 'gif'">
          <img :src="i.linkUrl" preview="1" preview-text='长按保存图片' alt="">
        </div>
        <div v-else>
          <a :href="i.linkUrl" :download="i.linkUrl">{{ i.name }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState ,mapGetters} from 'vuex';
import { getUsefulList } from '@/api/vote';

export default {
  data() {
    return {
      list: []
    }
  },
  computed: {
    ...mapState({
      thidrdId: 'thidrdId',
    }),
    ...mapGetters(["get_liveScene"]),
  },
  created() {
    this.getusefulList()
  },
  watch:{
    get_liveScene(data){
      console.log('直播现场',data[data.length-1]);
      for (let i of data[data.length-1]) {
          i.createDateTime = moment(i.createDateTime).format('YYYY-MM-DD HH:mm:ss')
        }
       this.list=data[data.length-1]
       this.$previewRefresh();
     }
   },
  methods: {
    getusefulList() {
      getUsefulList({ roomNo: this.$route.query.roomId }).then(res => {
        for (let i of res.data) {
          console.log(i.createDateTime);
          i.createDateTime = moment(i.createDateTime).format('YYYY-MM-DD HH:mm:ss')
        }
        this.list = res.data
        console.log(1111, this.list);
        this.$previewRefresh();
        
      })
    }

  },
};
</script>

<style lang="less" scoped>
.exan-container {
  text-align: left;
  height: 100%;
  overflow: hidden auto;
  
}
.header{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #C1C1C1;
}
.exan-container::-webkit-scrollbar {
  width: 6px;
  /*滚动条宽度*/
  height: 12px,
    /*滚动条高度*/
}

/*定义滚动条轨道 内阴影+圆角*/
.exan-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  /*滚动条的背景区域的圆角*/
  background-color: #F1F1F1;
  /*滚动条的背景颜色*/
}

/*定义滑块 内阴影+圆角*/
.exan-container::-webkit-scrollbar-thumb {
  border-radius: 6px;
  /*滚动条的圆角*/
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #C1C1C1;
  /*滚动条的背景颜色*/
}

.content {
  margin-left: 35px;
  box-sizing: border-box;
}
.title{
  margin-left: 35px;
  box-sizing: border-box;
}

img {
  width: 100%;

  //margin-top: 5rem;
}
// /deep/.pswp__caption__center{
//   text-align: right;
// }

@media screen and (max-width: 900px) {
  .mobile-horizontal-layout {
    .exan-container {
      display: none;
    }
  }
}

@media screen and (min-width: 900px) {
  .mobile-horizontal-layout {
    .exan-container {
      display: none;
    }
  }
}</style>
