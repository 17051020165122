<!--
 * @Description: PC 端页面header
 * @Date: 2021-10-31 15:59:54
 * @LastEditTime: 2022-01-26 15:34:57
-->
<template lang="pug">
  div.player-title-container(style={width: '100%',height:'100%',position:'relative',display:'flex',justifyContent :'space-between',alignItems :'center'})
    div.logo-container(style={fontSize: '18px',display: 'flex',alignItems: 'center'})
      div(v-for="item in roomInfo.logoCover")
        img.logo(:src="item || avatar")
      div.info-container(style={  colr :'#D9DADB'})
        div.info-my(style={fontSize: '20px',marginTop : '11px', marginBottom : '5px',textAlign:'left',color:'#000',fontWeight:'700'} :title='roomInfo.roomName') {{roomInfo.roomName}}
        div(style={display: 'flex',alignItems :'center'}).right-container
          div(style={display: 'flex',marginRight:'30PX'})
            div(style={textAlign:'left',color:'#000',display: 'flex',alignItems: 'center'})
               img.imgs(src="@/assets/img/user.png", alt="")
               span.info-span(style={marginLeft:'10px'}) {{"当前用户："}}
            comp-user-info(style={marginLeft:'20px',fontSize:'24px'})
          div(style={color:'#000'})
            div(style={display: 'flex',alignItems:'center'})
              img.imgs(src="../../../assets/img/hot.gif", alt="")
              span(style={marginLeft:'5px'}) {{hotScores}}
    //- 用户信息
    //- div.left-container
    //-   img.logo(:src="logo")
    //-   div.player-title
    //-     span {{ roomName }}
    //- div.right-container
    //-   div(style={display: 'flex',alignItems:'center'})
    //-     div(style={display: 'flex',alignItems:'center',marginRight:'20PX'})
    //-       img.imgs(src="../../../assets/img/look.png", alt="")
    //-       span(style={marginLeft:'5px'}) {{hotScores}}观看
    //-     div(style={display: 'flex',alignItems:'center'})
    //-       img.imgs(src="../../../assets/img/hot.gif", alt="")
    //-       span(style={marginLeft:'5px'}) {{hotScores}}
    //-   img(src="@/assets/img/zhibo_icon.png", alt="")
     
      
      //-  comp-language(pageName="player")
    //-   comp-exit
    //-   comp-user-info
</template>

<script>
import compLanguage from './comp-language';
// import compUserInfo from '@/components/pusher/comp-header/comp-user-info.vue';//直播老师userInfo
import compUserInfo from './comp-user-info';//当前用户userInfo
import compExit from './comp-exit';
import avatar from 'assets/img/avatar.png';
import logo from '@/assets/img/logo.png';
import { HOT_ONLINE} from "@/constants/localKeys"
import StorageUtils from "@/putils/StorageUtils"
import liveConfigInfo from '@/config/liveConfigInfo';
import { mapState,mapGetters } from 'vuex';
export default {
  name: 'compHeader',
  mixins: [liveConfigInfo],
  data() {
    return {
      logo,
      avatar,
      hotScores:''
    };
  },
  components: {
    compLanguage,
    compUserInfo,
    compExit,
  },
  computed: {
    ...mapState({
      roomInfo: 'roomInfo',
      userInfo: 'userInfo',
    }),
    ...mapGetters(["get_hotOnline"]),
  },
  async created() {
    let {roomId} =  this.$route.query;
    await this.getCacheRoomInfo(roomId);
         console.log(this.roomInfo); 
       this.hotScores=this.roomInfo.hotScores
  },
  watch:{
    get_hotOnline(data){
      console.log('热度',data);
      let arr={}
     for(let i of data){
        arr=i
     }
     this.hotScores=arr.hotScores
    }
   },
  methods: {
  },
};
</script>

<style lang="stylus" scoped>
.player-title-container
  width 100%

  height 100%
  position relative
  display flex
  justify-content space-between
  align-items center
  padding 0 16px 0 20px
   .logo-container
      height 36px
      font-size 18px
      display flex
      align-items center
      background-color red
      & > :not(:first-child)
        margin-left 10px

.logo
  width auto
  height 50px
  vertical-align bottom
  margin 1px 1px 0 10px
.info-container
  color #D9DADB
  margin-left 15px
  .info-my
    font-size 20px
    margin-top 11px
    margin-bottom 5px
    max-width  700px
    overflow hidden
    white-space nowrap
    text-overflow ellipsis
    -webkit-text-overflow ellipsis
.imgs{
  width 20px
}
.right-container{
  margin-right 10px
}
</style>
