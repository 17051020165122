const playBack = {
    state: {
      data: [],
    },
    mutations: {
      //修改存储
      alter_playback(state, str) {
        let list = state.data.push(str);
        return Object.assign({}, state, { data: list });
      },
    },
    actions: {
      //修改
      call_playback(context, str) {
        context.commit('alter_playback', str);
      },
    },
    getters: {
      //获取
      get_playback(state) {
        return state.data;
      },
    },
  };
  export default playBack;
  