<!--
 * @Description: PC端 IM 即时通讯组件
 * @Date: 2022-01-25 16:05:22
 * @LastEditTime: 2022-02-17 16:09:29
-->
<template lang="pug">
div.im-message-container
  //- 聊天区域
  div.content-top-chat(ref="box")
    div.out(v-if="messageList.length === 0") {{ $t('Chat now') }}
    div.single-message(v-for="message, index in messageList")
      //-div.msg_div(:class="message.userID == userInfo.userId?'left':'right'")
      div.msg_div(v-if="message.userID == userInfo.userId" @click="settimetzj")
        div(style="width: 90%;")
          div.message-info
            span(v-if='left==false')
              
            span.message-time(style="color: #666;" v-show='left') {{getMessageTime(message)}}
            span.user-name {{getUserNick(message)}}
          div.message-content-container
            div.message-content
              span(v-for="item, index in message.renderContent")
                //- 文字消息
                span(
                  v-if="item.name === 'text'"
                  :key="index"
                  v-clipboard:copy="item.content"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
                ) {{item.content }}
                //- 表情消息
                img.message-icon(
                  v-else-if="item.name === 'img'"
                  :src="item.src"
                  :key="index"
               )
        img#user-avatar(:src="message.userAvatar", alt="")
        //- div.message-send-fail(v-if="message.state === 'fail'" @click="sendMessage(message.content)")
        //-   svg-icon.icon-style(icon-name="error")
      div.msg_div(v-else)
        img#user-avatar(:src="message.userAvatar", alt="" @click="settimet")
        div(style="width: 90%;")
          div.message-info
            span.user-name {{getUserNick(message)}}
            span.message-time(style="color: #666;"  v-show='right') {{getMessageTime(message)}}
          div.message-content-container
            div.message-content(style="margin-left: initial;")
              span(v-for="item, index in message.renderContent")
                //- 文字消息
                span(
                  v-if="item.name === 'text'"
                  :key="index"
                ) {{item.content }}
                //- 表情消息
                img.message-icon(
                  v-else-if="item.name === 'img'"
                  :src="item.src"
                  :key="index"
               )
        
        //- div.message-send-fail(v-if="message.state === 'fail'" @click="sendMessage(message.content)")
        //-   svg-icon.icon-style(icon-name="error")

  div.divider

  //- 文字及表情输入区域
  div.content-bottom
    //- 表情选择
    div.content-bottom-feel
      el-popover(
        placement='top'
        trigger='click'
        v-model='popoverVisible'
      )
        div.emojis
          div.emoji(
            v-for="emoji in emojiName"
            :key="emoji"
            @click="chooseEmoji(emoji)"
          )
            img(:src="emojiUrl + emojiMap[emoji]")
        span.icon-button(slot="reference")
          //- svg-icon.emoji-icon(icon-name="emoji")
          img(src="../../../assets/img/smile-icon.png", alt="")
          img.imgs(src="../../../assets/img/img.png", alt="")
    //- 文字输入
    div.content-bottom-input
      textarea.input(
        ref="input"
        type="text"
        v-model="inputMsg"
        :placeholder="inputPlaceHolder"
        @keyup.enter="handleSendMsg"
        :disabled="isLiveEnded || showLoginAttention"
        required="required"
        maxlength='200'
      )
      span.send-btn(style={backgroundColor:'#1A73E8',color:'#FFF',display:'inline-block'})(@click="handleSendMsg") {{ $t('Send') }}
    div.login-attention(v-if="showLoginAttention")
      span {{ $t('Current visitor status') }}
      a.login-link(@click="handleLogin") {{ $t('login') }}
</template>

<script>
import tim from './tim.js';
import { Message } from 'element-ui';
export default {
  name: 'imMessage',
  mixins: [tim],
  data(){
    return{
      right:false,
      left:false
    }
  },
    created(){
    this.$eventBus.$off("timGroupBack"); 
    this.$EventBus.$on("timGroupBack", (data) => {
        this.quitGroupBack(data)
        // this.logout()
    });
    this.$eventBus.$off("timJoinGroup"); 
    this.$EventBus.$on("timJoinGroup",async (msg) => {
       this.joinGroup()
      
       
    });
  },
  methods:{
    settimet(){
      this.right=!this.right
    },
    settimetzj(){
      this.left=!this.left
    },
    onCopy(e){
      console.log(e.text);
      this.$message(
       {
        message:"成功复制"+e.text,
        type: 'success'
       }
      );
      },
  }
};
</script>

<style lang="stylus" scoped>
.im-message-container
  width 100%
  height 100%
  display flex
  flex-direction column
  justify-content center
  .content-top-chat
    flex-grow 1
    width 100%
    height 330px
    //margin 10px 0
    overflow-y auto
    //margin 10px 0
    //border-radius 10px
    padding 14px 8px
    color $fontColor
    font-size 14px
    background-color: #f5f5f5
    &::-webkit-scrollbar
      width 6px /*滚动条宽度*/
      height 12px /*滚动条高度*/
    /*定义滚动条轨道 内阴影+圆角*/
    &::-webkit-scrollbar-track
      -webkit-box-shadow inset 0 0 6px rgba(0, 0, 0, 0.3)
      border-radius 6px /*滚动条的背景区域的圆角*/
      background-color #F1F1F1 /*滚动条的背景颜色*/
    /*定义滑块 内阴影+圆角*/
    &::-webkit-scrollbar-thumb
      border-radius 6px /*滚动条的圆角*/
      -webkit-box-shadow inset 0 0 6px rgba(0, 0, 0, .3)
      background-color #C1C1C1 /*滚动条的背景颜色*/
    .out
      color #999
      margin 50% auto
    .single-message
      width 100%
      text-align right
      margin 10px 0
      .msg_div 
        display flex
      #user-avatar
        width 30px
        height 30px
        border-radius 30px
        margin-right 10px
      .message-info
        width 100%
        height 30px
        line-height 30px
        color $grayFontColor
        font-size 14px
        display flex
        justify-content space-between
        .user-name
          padding-right 12px
          color #000
          font-size 14px
      .message-content-container
        width 100%
        display flex
        text-align left 
        align-items right
        .message-content
          //width 90%
          max-width 78%
          background-color #fff;
          padding 5px
          border-radius 4px
          font-size 16px
          font-weight 500
          word-break break-all
          color #666
          border-radius 4px
          border 1px solid #ebe8e8
          margin-left: auto
          span
            display inline-block
            vertical-align center
            .message-icon
              width 20px
              height 20px
              vertical-align middle
        .message-send-fail
          width 24px
          height 24px
          margin-left 10px
          cursor pointer
          display flex
          align-items center

  .divider
    width 100%
    height 2px
    background-color $lineColor

  .content-bottom
    width 100%
    height 200px
    max-height 20%
    position relative
    div.content-bottom-feel
      width 100%
      text-align left
      margin-left 20px
      margin-top: 5px
      .icon-button
        cursor pointer
        .emoji-icon
          width 24px
          height 24px
          fill $fontColor
    div.content-bottom-input
      text-align left
      position relative
      margin 20px auto 0
      display flex
      .input
        color $grayFontColor
        top 0
        right 0
        width 100%
        padding 4px
        padding-left 20px
        background-color $IMThemeColor
        font-size 16px
        border none
        outline none
        resize none
        height 70px
        border-top 1px solid #ccc
        border-radius 5px
        &::-webkit-scrollbar
           width 6px /*滚动条宽度*/
    .send-btn
      width 40px
      line-height 26px
      display flex
      justify-content center
      align-items center
      position absolute
      right 5px
      bottom 85px
      text-align center
      border-radius 5px 
    .login-attention
      width 100%
      position absolute
      top 50%
      transform translate(0, -50%)
      .login-link
        cursor pointer

.emojis
  height 170px
  max-width 400px
  overflow scroll
  .emoji
    height 30px
    width 30px
    float left
    box-sizing border-box
    img
      width 30px
      height 30px
.emojis::-webkit-scrollbar
           display:none
.right
  //flex-direction row-reverse

  </style>

<style lang="scss" scoped>
::deep .el-popover {
   max-width: 300px;
}
.imgs{
  margin-left: 15px;
  width: 26px;
}
</style>>

<i18n>
{
	"en": {
		"Chat now": "Chat now！",
    "Type a message": "Type a message",
    "Current visitor status": "当前为游客身份，请先",
    "login": "登录",
    "Live room does not exist": "Live admin does not exist",
    "Send":"Send",
    "join Group fail": "Join Group fail, please refresh."
	},
	"zh": {
		"Chat now": "快来互动吧！",
    "Type a message": "参与互动",
    "Current visitor status": "当前为游客身份，请先",
    "login": "登录",
    "Live room does not exist": "直播间不存在",
    "Send":"发送",
    "join Group fail": "加入直播聊天群失败，请刷新重试"
	}
}
</i18n>
