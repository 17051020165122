import StringUtils from "@/putils/StringUtils";

const voteList = {
  state: {
    // data
    data: [],
  },
  mutations: {
    //修改存储
    alter_voteList(state, str) {
      let list = {};
      console.log(state.data.length)
      console.log(str.msgKey)
      console.log(StringUtils.isEmpty(str.msgKey));
      if(StringUtils.isEmpty(str.msgKey)){
        return state;
      }
      if(state.data.length == 0){
        list = str;
      }else{
        for(let i in state.data){
          let voteOne = state.data[i]
          console.log(voteOne.msgKey != str.msgKey);
          if(voteOne.msgKey != str.msgKey ){
            list = str;
          }else{
            if(voteOne.msgKey == str.msgKey){
              list = str;
            }
          }
        }
      }
      if (!StringUtils.isEmpty(list)){
        let arr = state.data.push(list);
        return Object.assign({}, state, { data: arr });
      }
      
      return state;
      // state.data = list;
      // return state;
    },
  },
  actions: {
    //修改
    call_voteList(context, str) {
      context.commit('alter_voteList', str);
    },
  },
  getters: {
    //获取
    get_voteList(state) {
      return state.data;
    },
  },
};
export default voteList;
