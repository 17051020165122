<!--
 * @Description: 标准直播播放
 * @Date: 2021-11-04 11:02:45
 * @LastEditTime: 2022-02-18 21:10:30
-->
<template lang="pug">
  div.cdn-stream
    video#cdn-video-container.stream(playsinline webkit-playsinline x5-video-player-fullscreen)
    div.loading(v-show="showLoading")
</template>

<script>
import {
  PLAY_STATE,
} from '@/constants/room';
import { UPDATE_PLAY_STATE } from '@/constants/mutation-types';
import { isAndroid, isMicroMessenger } from '@/putils/utils';
import { mapState } from 'vuex';
export default {
  name: 'compCdnStream',
  data() {
    return {
      player: null,
      isPlaying: false,
    };
  },
  computed: {
    ...mapState({
      sdkAppId: 'sdkAppId',
      playerDomain: 'playerDomain',
      playState: 'playState',
      roomInfo: 'roomInfo',
      audioLevel:'audioLevel',
      roomId: state => state.roomInfo.roomId,
      bgImg: state => state.roomInfo.bgImg,
    }),
    showLoading() {
      return this.playState === PLAY_STATE.PLAYING && !this.isPlaying;
    },
  },
  watch: {
    playState(val) {
      console.log("------------------这个什么时候改变")
      console.log(val)
      if (val === PLAY_STATE.PLAYING) {
        this.initPlayer();
        this.player && this.player.play();
      } else if (val === PLAY_STATE.PAUSED) {
        this.player && this.player.pause();
      }
    },
  },
  methods: {
    initPlayer() {
      // eslint-disable-next-line no-undef
      this.player = TCPlayer('cdn-video-container', {
        // controls: false,
        // autoplay: this.playState === PLAY_STATE.PLAYING,
        // poster:this.bgImg,
        // volume: this.audioLevel,
      });
      console.log("this.player-----------------------默认播放参数")
      this.player.src(`https://${this.playerDomain}/trtc_${this.sdkAppId}/${this.roomId}.m3u8`);
      this.playerListener();
    },
    playerListener() {
      this.player.on('error', (e) => {
        this.isPlaying = false;
        this.player.dispose();
        if (e.data.code != 14){
          setTimeout(() => {
            this.initPlayer();
          }, 60000);
        }
      });
      this.player.on('playing', () => {
        this.isPlaying = true;
      });
    },
    setVolumeValue(val){
      console.log("修改到声音没有")
      console.log(val)
      if (this.player){
        this.player.volume(val)
      }
    },
    destroyPlayer() {
      this.player && this.player.dispose();
    },
    playerWebrtcevent(){
      //播放结束的时候
      this.player.on('webrtcevent', function(event) {
        // 从回调参数 event 中获取事件状态码及相关数据
        console.log("playerWebrtcevent------------------")
        console.log(event)
      });
    }
  },
  created() {
    // Andriod 微信中 TCPlayer 自动播放会失败，需要手动点击播放
    if (isAndroid && isMicroMessenger) {
      this.$store.commit(UPDATE_PLAY_STATE, 'paused');
    }
  },
  mounted() {
    if (this.playerDomain === '') {
      console.log("cdn------------------------------------")
      alert(`${this.$t('basic.Please configure your playerDomain')}\r\n\r\nconfig/liveConfigInfo.js`);
      return;
    }
    // this.initPlayer();
  },
  beforeDestroy() {
    this.destroyPlayer();
  },
};
</script>


<style scoped lang="less">
/deep/.tcp-skin .vjs-control-bar{
  opacity: 1 !important;
}
</style>
<style lang="stylus" scoped>
.cdn-stream
  width 100%
  height 100%
  .stream
    width 100%
    height 100%
  .loading
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background-color #000000
    display flex
    &:before
        content "loading..."
        display block
        color #ffffff
        font-size 24px
        width 100px
        height 30px
        line-height 30px
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)

</style>
