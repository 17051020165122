import { render, staticRenderFns } from "./VideoControl.vue?vue&type=template&id=f2919d5a&scoped=true&"
import script from "./VideoControl.vue?vue&type=script&setup=true&lang=ts&"
export * from "./VideoControl.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./VideoControl.vue?vue&type=style&index=0&id=f2919d5a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2919d5a",
  null
  
)

export default component.exports