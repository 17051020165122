import request from '@/putils/request.js';
const urlList = {
  loginUrl:"/api/user/auth/login",
  liveTencentGetTxConfig: '/api/live/txRtc/getTxConfig',  //获取腾讯云配置
  getUserRoomInfoUrl: '/api/user/account/getUserRoomInfo',  //获取用户信息
  statrRoomResume:'/api/live/streaming/room/resume',// 主持人开始直播  恢复禁流
  forbidRoomResume:'/api/live/streaming/room/forbid/',// 主持人解散房间  直播间禁流(tx端)
  getLiveStreamingDetailUrl: "/api/live/streaming/room/detail", // 获取房间xian详情
};

/**
 * 获取腾讯云配置
 * @param params
 * @returns {Promise<any>}
 */
export function getLiveTencentGetTxConfig(params) {
  return request(urlList.liveTencentGetTxConfig, {
    method: 'get',
    params: params,
  });
}

/**
 * 获取用户信息
 * @param params
 * @returns {Promise<any>}
 */
export function getUserRoomInfo(params) {
  return request(urlList.getUserRoomInfoUrl, {
    method: 'post',
    params: params,
  });
}

/**
 * 主持人开始直播  恢复禁流
 * @param params
 * @returns {Promise<any>}
 */
export function statrRoomResume(params) {
  return request(urlList.statrRoomResume + "/"+ params.roomId, {
    method: 'get',
    params: params,
  });
}
/**
 * 主持人结束直播 禁流
 * @param params
 * @returns {Promise<any>}
 */
export function forbidRoomResume(params) {
  return request(urlList.forbidRoomResume + "/"+ params.roomId, {
    method: 'get',
    params: params,
  });
}


/**
 * 获取房间xian详情
 * @param params
 * @returns {Promise<any>}
 */
 export function getLiveStreamingDetail(params) {
  return request(urlList.getLiveStreamingDetailUrl, {
    method: 'get',
    params: params,
  });
}
