<!--
 * @Description: 播放器控制组件
 * @Date: 2021-10-31 16:34:20
 * @LastEditTime: 2022-01-21 16:29:07
 :style="{backgroundImage: 'url('+ bgImgs +')' }"
-->
<template lang="pug">
  div.stream-control-container(
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    @mousemove="handleMouseMove"
    :style="{backgroundImage: 'url('+ bgImgs +')' }"
    @mouseover ="mouseover" @mouseout="mouseout"
  )
    div.stream-center-control
      comp-play(@click.native="handlePlay")
    //- div.stream-bottom-control(:class="showStreamControl ? 'show' : 'hide'"   v-show='volume_shows')
    //-   div.left-container
        
    //-     //- 开始播放按
    //-     //- div.playing.control-item(v-if="playState === PLAY_STATE.PAUSED" @click="handlePlay")
    //-     //-   svg-icon.icon(icon-name="play")
    //-     //- 暂停播放按钮
    //-     //- div.paused.control-item(v-if="playState === PLAY_STATE.PLAYING" @click="handlePause")
    //-     //-   svg-icon.icon(icon-name="pause")
    //-     //- 分享按钮
    //-     comp-share
    //-     div(v-if='$isMobile')
    //-       div(style={'textAlign':'end','marginRight':'0.8rem'})
    //-         img.imgs(src="../../../../assets/img/hot.gif", alt="")
    //-         span(style={marginLeft:'5px',color:'#fff'}) {{hotScores}}
    //-   div.right-container
    //-     //- 线路选择组件
    //-     //- comp-line-check
    //-     //- 音量控制按钮/;
    //-     div.comp-div-val
    //-       img.svg-icon( v-if='!$isMobile' src="@/assets/img/volume-icon.png" @mouseover="mouseoverVolume" @mouseout="mouseoutVolume")
    //-       div.volume_style(v-show='volume_show'  @mouseover="mouseoverVolume" @mouseout="mouseoutVolume")
    //-         input(type="range" min="0" max="100" v-model="volumeLevel" @change="upVolumeValue")
    //-       //- 全屏/取消全屏组件
    //-       comp-full-screen(v-if='!$isMobile' )
    //-       comp-layout(v-if='$isMobile')
          

</template>

<script>
import { mapState ,mapGetters} from 'vuex';
import { UPDATE_PLAY_STATE,UPDATE_AUDIO_LEVEL } from '@/constants/mutation-types';
import { PLAY_STATE } from '@/constants/room';
import compPlay from './comp-play';
import compShare from './comp-share';
import compLineCheck from './comp-line-check';
import compFullScreen from './comp-full-screen';
import compLayout from './../../comp-header/comp-layout';
export default {
  name: 'compStreamControl',
  data() {
    return {
      PLAY_STATE,
      isFullScreen: false,
      showLineOptions: false,
      showStreamControl: false,
      volume_show:false,
      volume_shows:true,
      timer: null,
      bgImgs:"",
      volumeLevel:100,
      previousTime: 0,
      hotScores:''
    };
  },
  components: {
    compPlay,
    compLayout,
    compShare,
    compLineCheck,
    compFullScreen,
  },
  computed: {
    ...mapState({
      playState: 'playState',
      roomInfo: 'roomInfo',
      audioLevel:'audioLevel',
      bgImg: state => state.roomInfo.bgImg,
    }),
    ...mapGetters(["get_hotOnline"]),
  },
  created() {
       this.hotScores=this.roomInfo.hotScores
  },
  watch: {
    get_hotOnline(data){
      console.log('热度',data);
        let arr={}
        for(let i of data){
        arr=i
       }
     this.hotScores=arr.hotScores
    },
    bgImg: {
      immediate: true,
      handler(val) {
        this.bgImgs = val;
      },
    
    },
    audioLevel:{
      immediate: true,
      handler(val) {
        console.log("audioLevel ---val")
        console.log(val)
        this.volumeLevel = val;
      }
    }
  },
  methods: {
    handlePlay() {
      this.bgImgs = "";
      this.$store.commit(UPDATE_PLAY_STATE, 'playing');
    },
    handlePause() {
      this.$store.commit(UPDATE_PLAY_STATE, 'paused');
    },
    mouseoverVolume(){
      this.volume_show = true;
    },
    mouseoutVolume(){
      this.volume_show = false;
    },
    mouseover(){
      this.volume_shows = true;
    },
    mouseout(){
      this.volume_shows = false;
    },
    upVolumeValue(){
      console.log(this.volumeLevel)
      this.$store.commit(UPDATE_AUDIO_LEVEL, this.volumeLevel);
      let vol = this.volumeLevel / 100;
      this.$parent.updataVolume(vol);
    },
    handleMouseEnter() {
      this.showStreamControl = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(this.hideStreamControl, 6000);
    },
    handleMouseLeave() {
      this.hideStreamControl();
    },
    handleMouseMove() {
      const now = Date.now();
      if (now - this.previousTime > 500) {
        this.showStreamControl = true;
        clearTimeout(this.timer);
        this.timer = setTimeout(this.hideStreamControl, 6000);
        this.previousTime = now;
      }
    },
    hideStreamControl() {
      this.showStreamControl = false;
    },
  },
  mounted(){
    document.addEventListener('touchmove', function (event) {
      event.preventDefault();
    }, false);
  }
};
</script>

<style lang="stylus" scoped>
.stream-control-container
  width 100%
  height 100%
  background-repeat repeat
  background-size 100% 100%
  .stream-center-control
    width 100%
    height calc(100% - 50px)
  .stream-bottom-control
    display block
    width 100%
    height 30px
    position absolute
    bottom 0
    background-image linear-gradient(140deg, rgba(21,27,48,0.70) 0%, rgba(28,33,49,0.90) 100%)
    box-shadow 0 0 3px 0 rgba(32,32,32,0.40)
    transition transform 0.2s ease-out
    .left-container
      position absolute
      height 100%
      left -1.1rem
      display flex
      align-items center
      > div:not(:last-child)
        margin-right 30px
    .right-container
      position absolute
      height 100%
      right 30px
      display flex
      align-items center
      .comp-div-val
        display flex
        height 100%
        align-items center
      > div:not(:first-child)
        margin-left 30px
        position relative
      .volume_style
        position absolute
        top -140px
        width 60px
        height 180px
        input
          position absolute
          left -55px
          -webkit-appearance slider-vertical
          ::-webkit-slider-runnable-track
            -webkit-appearance none
      .svg-icon
        width 23px
        height 20px
    >>> .control-item
      &:hover
        color $highLightColor
        .icon
          fill $highLightColor
    >>> .icon
      cursor pointer
      width 32px
      height 32px
      fill $fontColor
.stream-control-container:hover
  .stream-bottom-control
    display block
.imgs{
  width 20px
}
</style>

<!-- <style scoped>
@media screen and (min-width: 900px){
  .stream-bottom-control {
   height: 40px;
}
}
@media screen and (max-width: 900px){
  .stream-bottom-control {
    height: 20px;
}
}
</style> -->

<i18n>
{
	"en": {
		"Link 1: TRTC ultra-low-latency streaming": "Link 1: TRTC ultra-low-latency streaming",
    "Link 2: LEB": "Link 2: LEB",
    "Link 3: LVB": "Link 3: LVB"
	},
	"zh": {
		"Link 1: TRTC ultra-low-latency streaming": "线路一：TRTC超低延时观看",
    "Link 2: LEB": "线路二：快直播观看",
    "Link 3: LVB": "线路三：标准直播观看"
	}
}
</i18n>
