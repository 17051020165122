import { render, staticRenderFns } from "./memberInfo.vue?vue&type=template&id=15ca2631&scoped=true&"
import script from "./memberInfo.vue?vue&type=script&setup=true&lang=ts&"
export * from "./memberInfo.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./memberInfo.vue?vue&type=style&index=0&id=15ca2631&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15ca2631",
  null
  
)

export default component.exports