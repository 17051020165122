<template>
  <div id="pusher">
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: "pusher",
};
</script>

<style lang="less" scoped>
#mobile{
  // background: #000;
}
</style>
