import { render, staticRenderFns } from "./playbackfooter.vue?vue&type=template&id=570e5e09&scoped=true&"
import script from "./playbackfooter.vue?vue&type=script&lang=js&"
export * from "./playbackfooter.vue?vue&type=script&lang=js&"
import style0 from "./playbackfooter.vue?vue&type=style&index=0&id=570e5e09&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "570e5e09",
  null
  
)

export default component.exports