import { defineStore } from 'pinia';
import { ETUISpeechMode, ETUIRoomRole, TRTCStatistics,
  TRTCVideoStreamType, ScreenProfile, RoomInfo } from '../TUIRoom/tui-room-core';
import { LAYOUT } from '../constants/render';
import { ROOM_LIVE_STAGE } from "@/constants/localKeys"
import StorageUtils from "@/putils/StorageUtils";

type SideBarType = 'chat' | 'invite' | 'manage-member' | 'more' | '';
type ModelType = 'chat' | 'invite' | 'manage-member' | 'more' | '';
const piniaBasicStorage : Storage  = {
  setItem (key, state): void {
    return StorageUtils.setSessionItem(key, "",state)
  },
  getItem (key) : any{
    return StorageUtils.getSessionItem(key,"");
  },
  clear(): void{},
  removeItem(): void{},
  key(index: number): any {},
  length
}

interface BasicState {
  sdkAppId: number,
  liveStageRoom: string,
  userId: string,
  imUserId: string,
  userSig: string,
  shareUserId: string,
  sharingStatus: boolean,
  shareUserSig?: string,
  userName: string,
  userAvatar?: string,
  votemsgContent?: string,
  userRole: string,
  useStringRoomId: boolean,
  roomId: number,
  roomInfos:RoomInfo,
  roomMode: ETUISpeechMode,
  isSidebarOpen: boolean,
  isModelOpen: boolean,
  showSettingDialog: boolean,
  showVoteDialog: boolean,
  showApplyUserList: boolean,
  activeSettingTab: string,
  layout: LAYOUT,
  isLocalStreamMirror: boolean,
  sidebarName: SideBarType,
  modelTName: ModelType,
  role: ETUIRoomRole | null,
  masterUserId: string,
  localQuality: number,
  statistics: TRTCStatistics,
  isMuteAllAudio: boolean,
  isMuteAllVideo: boolean,
  canControlSelfAudio: boolean,
  canControlSelfVideo: boolean,
  screenProfile:ScreenProfile,
  isallScreen:boolean,
}

export const useBasicStore = defineStore('basic', {
  state: (): BasicState => ({
    sdkAppId: 0,
    //直播间阶段
    liveStageRoom: '',
    userId: '',
    imUserId: '',
    userSig: '',
    shareUserId: '',
    sharingStatus:false,
    shareUserSig: '',
    userName: '',
    userAvatar: '',
    votemsgContent:"",
    //用户角色
    userRole:'',
    useStringRoomId: false,
    roomId: 0,
    roomInfos:{
      roomId:0,
      // 直播间昵称
      roomName: '',
      bgImg:"",
      logoCover:"",
      //限制类型：no_filter-没有限制； register_filter-登记观看 visitor_filter-游客观看
      watchFilter:"",
      //房间状态：no_live-no_live-未开播 live_ing-直播中 forbidden-房间封禁 live_pause-直播暂停 live_over-直播结束
      roomStatus:"",
      //直播间类型： meeting-多人会议； live-单人直播； record-录播
      roomType:'',
      startTime:'',
      hotScores:'0',
      
    },
    roomMode: ETUISpeechMode.APPLY_SPEECH,
    isSidebarOpen: true,
    isModelOpen: false,
    isallScreen:false,
    //默认顶部成员列表
    layout: LAYOUT.NINE_EQUAL_POINTS,
    // layout: LAYOUT.TOP_SIDE_LIST,
    showSettingDialog: false,
    showVoteDialog:false,
    showApplyUserList: false,
    activeSettingTab: 'audio',
    isLocalStreamMirror: true,
    sidebarName: '',
    modelTName: '',
    // 屏幕分享参数
    screenProfile: {
      // width: 1920,
      // height: 1080,
      width: Math.floor(1920 * 0.85) ,
      height: Math.floor(1080 * 0.85),
      frameRate: 15,
      bitrate: 1200,
    },
    role: null,
    masterUserId: '',
    localQuality: 0,
    statistics: {
      appCpu: 0,
      downLoss: 0,
      localStatisticsArray: [],
      localStatisticsArraySize: 0,
      receivedBytes: 0,
      remoteStatisticsArray: [],
      remoteStatisticsArraySize: 0,
      rtt: 0,
      sentBytes: 0,
      systemCpu: 0,
      upLoss: 0,
    },
    isMuteAllAudio: false,
    isMuteAllVideo: false,
    // 主持人全员禁麦，但是单独取消某个用户音视频禁止状态的时候，是可以自己 unmute audio/video 的
    canControlSelfAudio: true,
    canControlSelfVideo: true
  }),
  getters: {
    localVideoBitrate: (state) => {
      const localStatistics = state.statistics.localStatisticsArray
        .find(item => item.streamType === TRTCVideoStreamType.TRTCVideoStreamTypeBig);
      if (localStatistics && localStatistics.videoBitrate)  {
        return localStatistics.videoBitrate;
      }
      return 0;
    },
    localFrameRate: (state) => {
      const localStatistics = state.statistics.localStatisticsArray
        .find(item => item.streamType === TRTCVideoStreamType.TRTCVideoStreamTypeBig);
      if (localStatistics && localStatistics.frameRate)  {
        return localStatistics.frameRate;
      }
      return 0;
    },
    isMaster(state) {
      return state.role === ETUIRoomRole.MASTER;
    },
    isAnchor(state) {
      return state.role === ETUIRoomRole.ANCHOR;
    },
    isAudience(state) {
      return state.role === ETUIRoomRole.AUDIENCE;
    },
    isLocalAudioIconDisable(state) {
      // 全员禁麦状态
      const micForbidden = state.userId !== state.masterUserId && !state.canControlSelfAudio;
      // 举手发言麦下模式
      const audienceRole = state.roomMode === ETUISpeechMode.APPLY_SPEECH && state.role === ETUIRoomRole.AUDIENCE;
      return micForbidden || audienceRole;
    },
    isLocalVideoIconDisable(state) {
      // 全员禁画状态
      const cameraForbidden = state.userId !== state.masterUserId && !state.canControlSelfVideo;
      // 举手发言麦下模式
      const audienceRole = state.roomMode === ETUISpeechMode.APPLY_SPEECH && state.role === ETUIRoomRole.AUDIENCE;
      return cameraForbidden || audienceRole;
    },
    getUserId(state){
      return state.userId
    }
  },
  actions: {
    setSdkAppId(sdkAppId: number) {
      this.sdkAppId = sdkAppId;
    },
    setLiveStageRoom(liveStageRoom: string) {
      this.liveStageRoom = liveStageRoom;
      StorageUtils.setSessionItem(ROOM_LIVE_STAGE,{userId:this.userId},liveStageRoom)
    },
    setUserId(userId: string) {
      this.userId = userId;
    },
    setImUserId(imUserId: string) {
      this.imUserId = imUserId;
    },
    setUserSig(userSig: string) {
      this.userSig = userSig;
    },
    setShareUserId(shareUserId: string) {
      this.shareUserId = shareUserId;
    },
    setSharingStatus(sharingStatus: boolean) {
      this.sharingStatus = sharingStatus;
    },
    setShareUserSig(shareUserSig: string) {
      this.shareUserSig = shareUserSig;
    },
    setUserName(userName: string) {
      this.userName = userName;
    },
    setUserAvatar(userAvatar: string) {
      this.userAvatar = userAvatar;
    },
    setVotemsgContent(votemsgContent: string) {
      this.votemsgContent = votemsgContent;
    },
    setUserRole(userRole: string) {
      this.userRole = userRole;
    },
    setRoomId(roomId: number) {
      this.roomId = roomId;
      this.useStringRoomId = typeof roomId === 'string';
    },
    setRoomInfos(roomInfo: RoomInfo) {
      this.roomInfos = roomInfo;
    },
    setRoomMode(mode: ETUISpeechMode) {
      this.roomMode = mode;
    },
    setSidebarOpenStatus(isOpen: boolean) {
      this.isSidebarOpen = isOpen;
    },
    setModelOpenStatus(isOpen: boolean) {
      this.isModelOpen = isOpen;
    },
    setisallScreen(allScreen:boolean){
      this.isallScreen=allScreen
    },

    setSidebarName(name: SideBarType) {
      this.sidebarName = name;
    },
    setModelTName(name: ModelType) {
      this.modelTName = name;
    },
    setLayout(layout: LAYOUT) {
      this.layout = layout;
    },
    setShowSettingDialog(show: boolean) {
      this.showSettingDialog = show;
    },
    setShowVoteDialog(show: boolean) {
      this.showVoteDialog = show;
    },
    setShowApplyUserList(show: boolean) {
      this.showApplyUserList = show;
    },
    setActiveSettingTab(tabName: string) {
      this.activeSettingTab = tabName;
    },
    setIsLocalStreamMirror(mirror: boolean) {
      this.isLocalStreamMirror = mirror;
    },
    setBasicInfo(infoObj: any) {
      const { sdkAppId, userId, imUserId, userSig, shareUserId, shareUserSig,votemsgContent, userName, userAvatar,userRole, roomId, roomInfos } = infoObj;
      sdkAppId && this.setSdkAppId(sdkAppId);
      userId && this.setUserId(userId);
      imUserId && this.setImUserId(imUserId);
      userSig && this.setUserSig(userSig);
      shareUserId && this.setShareUserId(shareUserId);
      shareUserSig && this.setShareUserSig(shareUserSig);
      userName && this.setUserName(userName);
      userAvatar && this.setUserAvatar(userAvatar);
      votemsgContent && this.setVotemsgContent(votemsgContent);
      userRole && this.setUserRole(userRole);
      roomId && this.setRoomId(roomId);
      roomInfos && this.setRoomInfos(roomInfos);
    },
    setRole(role: ETUIRoomRole) {
      this.role = role;
    },
    setMasterUserId(userId: string) {
      this.masterUserId = userId;
    },
    setRoomInfo(roomInfo: {
      ownerId: string,
      roomConfig: {
        isAllMicMuted: boolean,
        isAllCameraMuted: boolean,
        speechMode: ETUISpeechMode
      }
    }) {
      const { ownerId, roomConfig: { isAllMicMuted, isAllCameraMuted, speechMode } } = roomInfo;
      this.masterUserId = ownerId;
      if (this.userId === ownerId) {
        this.role = ETUIRoomRole.MASTER;
      } else {
        this.role = speechMode === ETUISpeechMode.APPLY_SPEECH ? ETUIRoomRole.AUDIENCE : ETUIRoomRole.ANCHOR;
      }
      this.isMuteAllAudio = isAllMicMuted;
      this.isMuteAllVideo = isAllCameraMuted;
      if (this.isMuteAllAudio) {
        this.canControlSelfAudio = false;
      }
      if (this.isMuteAllVideo) {
        this.canControlSelfVideo = false;
      }
      this.roomMode = speechMode;
    },
    setLocalQuality(quality: number) {
      this.localQuality = quality;
    },
    setStatistics(statistics: TRTCStatistics) {
      this.statistics = statistics;
    },
    setIsMuteAllAudio(isMuteAllAudio: boolean) {
      this.isMuteAllAudio = isMuteAllAudio;
    },
    setIsMuteAllVideo(isMuteAllVideo: boolean) {
      this.isMuteAllVideo = isMuteAllVideo;
    },
    setCanControlSelfAudio(capability: boolean) {
      this.canControlSelfAudio = capability;
    },
    setCanControlSelfVideo(capability: boolean) {
      this.canControlSelfVideo = capability;
    },
    reset() {
      this.isSidebarOpen = false;
      this.layout = LAYOUT.TOP_SIDE_LIST;
      this.showSettingDialog = false;
      this.showVoteDialog=false;
      this.activeSettingTab = 'audio';
      this.isLocalStreamMirror = true;
      this.sidebarName = '';
      this.masterUserId = '';
      this.localQuality = 0;
      this.isMuteAllAudio = false;
      this.isMuteAllVideo = false;
    },
  },
  // 所有数据持久化
  // persist: true,
  // 持久化存储插件其他配置
  // 持久化存储
  persist: {
    enabled: true,
    strategies: [{
      key: "basicStorage",
      storage: sessionStorage,//localStorage存储多个key
    },]
  },
});
