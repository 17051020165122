const iMmessage = {
  state: {
    // data
    data: [],
  },
  mutations: {
    //修改存储
    alter_imMessage(state, str) {
      let isExist=true
      for(let i of state.data){
        if(i.time ==str.time && i.content==str.content ){
           isExist=false
        }
      }
      if(isExist==true){
        let list = state.data.push(str);
      // console.log(state);
      // console.log(str);
      return Object.assign({}, state, { data: list });
      }
      
    },
  },
  actions: {
    //修改
    call_imMessage(context, str) {
      context.commit('alter_imMessage', str);
    },
  },
  getters: {
    //获取
    get_imMessageList(state) {
      return state.data;
    },
  },
};
export default iMmessage;
