<template>
   <div>
      <!-- <video 
         controls="controls" 
         webkit-playsinline='true'
          playsinline='true' 
          preload="auto" 
          :src="playbacklist"
        >
        <source :src="playbacklist" />
      </video> -->
      <div id="video" style="width: 100%;"></div>
      
  </div>
</template>

<script>
import Player from 'xgplayer';
import HlsJsPlayer from "xgplayer-hls.js";
import { mapState, } from 'vuex';
import {playbackList } from '@/api/player/player';
export default {
   data() {
      return {
         url:"",
         playback:{
          userId:"",
          playSort:"",
          roomNo:""
         },
         player: null, //实例
         viderurls:{}
      }
   },
   computed: {
      ...mapState({
         videoUrl:'videoUrl'
      }),
    
    },
    watch: {
      videoUrl(data){
         this.url=data
         if(this.player){
            this.player.destroy()
         }
         this.init()
      }
  },
   mounted(){
      // this.url=viderurls.videoURL
      //  this.init()
    },
   created() {
      let {roomId,userId} =  this.$route.query;
      this.playback.roomNo=roomId
      this.playback.userId=userId
      playbackList(this.playback).then(res=>{
            this.viderurls=res.data.playbackVideos.shift()
            this.url=this.viderurls.videoURL
            this.init()
      }).catch(err=>{
        console.log(err);
      })
     
      
   },
   
   methods: {
      init() {
      this.$nextTick(() => {
         let videoType = this.url.substring(this.url.lastIndexOf('.')+1);
         
         if(videoType=='m3u8'){
            this.player = new HlsJsPlayer({
          id: "video",
          url: this.url,
          autoplay: true,
          poster:'https://i.ytimg.com/vi/lK2ZbbQSHww/hqdefault.jpg',
          
          playsinline: true,
         //  rotateFullscreen: true
          //cors: true, 请求是否跨域 
        });
         }else{
            this.player = new Player({
          id: "video",
          url: this.url,
          autoplay: true,
          poster:"https://i.ytimg.com/vi/lK2ZbbQSHww/hqdefault.jpg",
          playsinline: true,
         //  rotateFullscreen: true
          //cors: true, 请求是否跨域 
        });
         }
        
      });
    },

   
  },
  

}
</script>

<style scoped>
video {
   height: auto;
   width: 100%
}
/deep/.xgplayer-enter-spinner{
   width: 30px !important;
   height: 30px !important;
}

@media screen and (min-width: 900px) {
   .xgplayer-skin-default{
   width: 100% !important;
   height: 485px !important;
}
}

@media screen and (max-width: 900px) {
   .xgplayer-skin-default{
   width: 100% !important;
   height: 270px !important;
}
}

</style>