  // const CryptoJS = require('crypto-js');  //引用AES源码js
import CryptoJS from 'crypto-js';
import MD5 from 'crypto-js/md5';

let md5str = MD5('123456789')
  .toString()
  .substr(8, 16)
  .toUpperCase();
const key = CryptoJS.enc.Utf8.parse('pixgram4clientiv'); //十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse(md5str); //十六位十六进制数作为密钥偏移量
export default class AesUtils {
  //解密方法
  static Decrypt(word) {
    if (word == null || word == '' || word == undefined) {
      return '';
    }
    let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
    let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    let decrypt = CryptoJS.AES.decrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  }

  //加密方法
  static Encrypt(word) {
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(word, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString().toUpperCase();
  }

  static base64Dectypt(base64) {
    var parsedWordArray = CryptoJS.enc.Base64.parse(base64);
    var parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
    return parsedStr;
  }
}
