import StringUtils from './StringUtils';

/**
 * 提供对sessionStorage和localStorage存取Object的操作.
 * 如果存取string,直接用原生api即可.
 *
 */
export default class StorageUtils {
    /**
     * 注意value是对象,不是字符串.
     * @param key string
     * @param valueObj object
     */
    static setSessionItem(key, query={}, valueObj) {
        //本地没有数据
        let obj = {
            data: valueObj, //需要保存的参数
            time: Date.now(),//存入当前时间
            expire: 28800000 //8小时的过期时间
        };
        if(!StringUtils.isEmpty(query.userId)){
            key=key.concat('-').concat(query.userId)
        }
        if(!StringUtils.isEmpty(query.roomId)){
            key=key.concat('-').concat(query.roomId)
        }
        if (valueObj != null) {
            localStorage.setItem(key, JSON.stringify(obj));
        } else {
            localStorage.setItem(key, null);
        }
    }

    /**
     *
     * @param {*} key 根据key,获取session中存放的对象
     */
    static getSessionItem(key, query={}) {
        if(!StringUtils.isEmpty(query) && !StringUtils.isEmpty(query.userId)){
            key=key.concat('-').concat(query.userId)
        }
        let val = localStorage.getItem(key);
        if (StringUtils.isEmpty(val)) {
            return val;
        }

        try{
            val = JSON.parse(val);
            if (Date.now() - val.time > val.expire) {
                localStorage.removeItem(key);
                return '';
            }
            return val.data;
        }catch(err){
            localStorage.removeItem(key);
            return '';
        }
        // return localStorage.getItem(key);
    }

    static removeSessionKey(key) {
        return localStorage.removeItem(key);
    }

    static setLocalItem(key, query, valueObj) {
        //本地没有数据
        let obj = {
            data: valueObj, //需要保存的参数
            time: Date.now(),//存入当前时间
            expire: 28800000 //8小时的过期时间
        };
        if(!StringUtils.isEmpty(query.userId)){
            key=key.concat('-').concat(query.userId)
        }
        if(!StringUtils.isEmpty(query.roomId)){
            key=key.concat('-').concat(query.roomId)
        }
        if (valueObj != null) {
            localStorage.setItem(key, JSON.stringify(obj));
        } else {
            localStorage.setItem(key, null);
        }
    }

    static getJsonSessionItem(key, query) {
        if(!StringUtils.isEmpty(query) && !StringUtils.isEmpty(query.userId)){
            key=key.concat('-').concat(query.userId)
        }
        let val = localStorage.getItem(key);
        if (StringUtils.isEmpty(val)) {
            return val;
        }

        try{
            val = JSON.parse(val);
            if (Date.now() - val.time > val.expire) {
                localStorage.removeItem(key);
                return '';
            }
            return val.data;
        }catch(err){
            localStorage.removeItem(key);
            return '';
        }
        // return JSON.parse(localStorage.getItem(key));
    }

    static clearLocalStorage() {
        sessionStorage.clear();
        return localStorage.clear();
    }

  /**
   * 过期时间
   * @param key
   * @returns {string|*}
   */
  static getExpire(key){
      let val = localStorage.getItem(key);
      if (!val) {
        return val;
      }
      val = JSON.parse(val);
      if (Date.now() - val.time > val.expire) {
        localStorage.removeItem(key);
        return '';
      }
      return val.data;
    }
    /**
     * 获取存放在sessionStorage中session的session_id.
     * 当session_id不存在时,则返回''字符串.
     */
    static getSessionId() {
        let localSession = StorageUtils.getSessionItem('session') || {};
        let sessionId = StringUtils.isNotBlank(localSession.session_id)
            ? localSession.session_id
            : '';

        return sessionId;
    }

    /**
     * set 添加 sessionStorage
     */
    static setSessionCache(key, query={}, valueObj){
     
      //本地没有数据
      let obj = {
        data: valueObj, //需要保存的参数
        time: Date.now(),//存入当前时间
        expire: 28800000 //8小时的过期时间
      };
      if(!StringUtils.isEmpty(query.userId)){
        key=key.concat('-').concat(query.userId)
      }
      if(!StringUtils.isEmpty(query.roomId)){
        key=key.concat('-').concat(query.roomId)
      }

      if (valueObj != null) {
        sessionStorage[key] = JSON.stringify(obj);
      } else {
        localStorage.setItem(key, null);
      }
    }

    /**
     * 获取 sessionStorage
     */
    static getSessionCache(key, query={}){
      if(!StringUtils.isEmpty(query) && !StringUtils.isEmpty(query.userId)){
        key=key.concat('-').concat(query.userId)
      }
      let val = sessionStorage.getItem(key);
      if (StringUtils.isEmpty(val)) {
        return val;
      }

      try{
        val = JSON.parse(val);
        if (Date.now() - val.time > val.expire) {
          sessionStorage.removeItem(key);
          return '';
        }
        return val.data;
      }catch(err){
        sessionStorage.removeItem(key);
        return '';
      }
    }

    /**
     * 删除 key sessionStorage
     */
    static delRoomSessionCache(query={}){
      var localKeys = Object.keys(localStorage);
      for (var i=0;i<localKeys.length;i++) {
        if ((localKeys[i].indexOf(query.userId) != -1 || localKeys[i].indexOf(query.roomId) != -1) && localKeys[i].indexOf("authToken") != -1){
          localStorage.removeItem(localKeys[i])
        }
      }
      localStorage.clear();
    }

    static delRoomtim(userId){
      var localKeys = Object.keys(localStorage);
      console.log(localKeys);
      for (var i=0;i<localKeys.length;i++) {
        if ((localKeys[i].indexOf("TIM") != -1 && localKeys[i].indexOf(userId) != -1)){
           console.log(localKeys[i]) 
           window.localStorage.removeItem(localKeys[i])
        }
      }
     
    }


    /**
     * 清空 sessionStorage
     */
    static clearSessionCache(){
      sessionStorage.clear();
    }
}
