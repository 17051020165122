/**
 * 字符串工具类.
 */

export default class StringUtils {
    static isNotBlank(str) {
        if (str && str.length > 0) {
            let regexp = /\S/g;
            return regexp.test(str);
        }
        return false;
    }

  /**
   * 判断字符串是否相等
   * @param o1
   * @param o2
   * @returns {boolean}
   */
    static isObjEqual(o1,o2){
      var props1 = Object.getOwnPropertyNames(o1);
      var props2 = Object.getOwnPropertyNames(o2);
      if (props1.length != props2.length) {
        return false;
      }
      for (var i = 0,max = props1.length; i < max; i++) {
        var propName = props1[i];
        if (o1[propName] !== o2[propName]) {
          return false;
        }
      }
      return true;
    }
    /**
     * 对象格式化拼接
     * @param {any} data 对象 eg:{a:'a',b:'b',c:1}]
     * return a=a&b=b&c=1
     */
    static formatGetUrl(data) {
        // this.defaultPaging(data)
        let ret = '';
        for (const i in data) {
            ret = ret.length > 0 ? ret + '&' : ret;
            ret = ret + i + '=' + data[i];
        }
        return ret;
    }

    /**
     * 判断地址url 是否包含http / https
     * @param URL
     */
    static checkURL(URL) {
        if (URL == '' || URL == null) {
            return false;
        }
        var str = URL,
            Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/,
            objExp = new RegExp(Expression);
        if (objExp.test(str) == true) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * 根据当前的播放时间修改当前的时间显示
     * @param {*} nowPlayTime
     */
    static fixTimeNowTime(nowPlayTime) {
        // 当前的秒数
        let mm = Math.floor((parseInt(nowPlayTime) / 60) % 60);
        mm = mm < 10 ? '0' + mm : mm;
        var ss = parseInt(nowPlayTime) % 60;
        ss = ss < 10 ? '0' + ss : ss;
        console.log(`${mm}:${ss}`);
        return `${mm}:${ss}`;
    }

    /**
     * * 时间转为秒 * @param time 时间(00:00:00)
     * * @returns {string} 秒数
     * */
    static time_to_sec(time) {
        var s = 0;
        var hour = time.split(':')[0];
        var min = time.split(':')[1];
        var sec = time.split(':')[2];

        if (hour != undefined) {
            s += Number(hour * 3600);
        }
        if (min != undefined) {
            s += Number(min * 60);
        }
        if (sec != undefined) {
            s += Number(sec);
        }
        return s;
    }

    /**
     * 获取某元素以浏览器左上角为原点的坐标
     * @param obj
     */
    static getPoint(obj) {
        var t = obj.offsetTop; //获取该元素对应父容器的上边距
        var l = obj.offsetLeft; //对应父容器的上边距
        //判断是否有父容器，如果存在则累加其边距
        while ((obj = obj.offsetParent)) {
            //等效 obj = obj.offsetParent;while (obj != undefined)
            t += obj.offsetTop; //叠加父容器的上边距
            l += obj.offsetLeft; //叠加父容器的左边距
        }
        return {x: l, y: t};
    }

    static extend() {
        var deep = false,
            result,
            args = arguments; //定义变量 deep为是否深拷贝， result为最终对象， args为源对象
        function child_extend(target, source, deep) {
            if (typeof source === 'object') {
                for (var i in source) {
                    var temp = source[i];
                    // 如果source[i]依旧是对象，并且需要深拷贝
                    if (deep && typeof temp === 'object') {
                        // 初始化target[i],根据object和array进行不同的赋值
                        if (StringUtils.isArray(temp)) {
                            target[i] = [];
                        } else {
                            target[i] = {};
                        }
                        child_extend(target[i], temp, deep); // 递归
                    } else if (typeof temp !== 'undefined') {
                        // 如果source[i]是基本数据类型，且不是undefined
                        target[i] = temp;
                    }
                }
            }
        }

        if (args.length > 0) {
            if (typeof args[0] === 'boolean') {
                // 如果设置了是否深拷贝，则目标对象初始化为第二个参数， 源对象设置为第三个参数
                deep = args[0];
                result = args[1];
                args = Array.prototype.slice.call(arguments, 2);
            } else {
                // 没有设置是否深拷贝的值，则目标对象初始化为第一个参数， 源对象设置为第二个参数
                result = args[0];
                args = Array.prototype.slice.call(arguments, 1);
            }

            args.forEach(element => {
                // 循环遍历每一个参数
                child_extend(result, element, deep);
            });
        }
        return result;
    }

    static isArray(arg) {
        return Object.prototype.toString.call(arg) === '[object Array]'; // 判断是否是数组的方法
    }

    /**
     * @param {*} arg1 数字
     * @param {*} arg2 数字
     * @description 两数字相加 保持精度
     */
    static accAdd(arg1, arg2) {
        let r1, r2, m;
        try {
            r1 = arg1.toString().split('.')[1].length;
        } catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split('.')[1].length;
        } catch (e) {
            r2 = 0;
        }
        m = Math.pow(10, Math.max(r1, r2));
        return (arg1 * m + arg2 * m) / m;
    }

    /**
     * @param {*} arg1 数字
     * @param {*} arg2 数字
     * @description 两数字相减 保持精度
     */
    static accSub = (arg1, arg2) => {
        let r1, r2, m, n;
        try {
            r1 = arg1.toString().split('.')[1].length;
        } catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split('.')[1].length;
        } catch (e) {
            r2 = 0;
        }
        m = Math.pow(10, Math.max(r1, r2));
        n = r1 >= r2 ? r1 : r2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);
    };

    /**
     * @param {*} arg1 数字
     * @param {*} arg2 数字
     * @description 两数字相乘 保持精度
     */
    static accMul(arg1, arg2) {
        let m = 0;
        const s1 = arg1.toString();
        const s2 = arg2.toString();
        try {
            m += s1.split('.')[1].length;
        } catch (e) {
        }
        try {
            m += s2.split('.')[1].length;
        } catch (e) {
        }
        return (
            (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) /
            Math.pow(10, m)
        );
    }

    //修改之后的除法
    /**
     * @param {*} arg1 数字
     * @param {*} arg2 数字
     * @description 两数字相除 保持精度
     */
    static accDiv = (arg1, arg2) => {
        let t1 = 0;
        let t2 = 0;
        let r1, r2;
        try {
            t1 = arg1.toString().split('.')[1].length;
        } catch (e) {
        }
        try {
            t2 = arg2.toString().split('.')[1].length;
        } catch (e) {
        }
        r1 = Number(arg1.toString().replace('.', ''));
        r2 = Number(arg2.toString().replace('.', ''));
        let intDiv = r1 / r2;
        let pow = Math.pow(10, t2 - t1);
        return StringUtils.accMul(intDiv, pow); // 这里用上面定义好的乘法运算
    };

    static isEmpty(obj) {
        if (
            typeof obj == 'undefined' ||
            obj == null ||
            obj == '' ||
            Object.keys(obj).length === 0
        ) {
            return true;
        } else {
            return false;
        }
    }

    static strIsNotContains = function (arr, obj) {
        var index = arr.length;
        while (index--) {
            if (arr[index] === obj) {
                return true;
            }
        }
        return false;
    }

    static isEmpty2(obj) {
        if (
            typeof obj == 'undefined' ||
            obj == null ||
            obj == '' ||
            Object.keys(obj).length === 0
        ) {
            return true;
        } else {
            let text = 'https://test-nft-cd-image.obs.cn-north-4.myhuaweicloud.com/';
            if (obj.indexOf(text) >= 0) {
                let url = obj.replace(text, '');
                if (url) {
                    return false;
                } else {
                    return true;
                }
            }
        }
    }
}
