<template>
     <div class="box">
      <room-container
      ref="TUIRoomRef"
      @on-log-out="handleLogOut"
      @on-create-room="onCreateRoom"
      @on-enter-room="onEnterRoom"
      @on-exit-room="onExitRoom"
      @on-destroy-room="onDestroyRoom"
      @on-kick-off="onKickOff"
    ></room-container>

     </div>
</template>

<script>
import RoomContainer from '@/TUIRoom';
import StorageUtils from '@/putils/StorageUtils';
import { USER_ROLE_WATCH,USER_ROLE_MAJOR } from "@/constants/localKeys"
import { LIVE_STAGE } from '@/constants/room';
import StringUtils from '@/putils/StringUtils';
import {  stopRoom, } from '@/api/vote';

export default {
  name: 'room',
  components: { RoomContainer },
  data() {
    return {
      roomInfo: null,
      userInfo: null,
      roomId: 0,

    };
  },
  async mounted() {
    this.roomInfo = sessionStorage.getItem('tuiRoom-roomInfo');
    this.userInfo = sessionStorage.getItem('tuiRoom-userInfo');

    this.roomId = this.$route.query.roomId;
    if (!this.roomId || !this.roomInfo) {
      this.$router.push({ path: 'admin' });
    }

    const { action, roomMode, roomParam } = JSON.parse(this.roomInfo);
    const { sdkAppId, userId,imUserId, userSig, shareUserId, shareUserSig, userName, userAvatar, userRole, roomInfos } = JSON.parse(this.userInfo);

    await this.$refs.TUIRoomRef.init({
      sdkAppId,
      userId,
      imUserId,
      userSig,
      userName,
      userAvatar,
      shareUserId,
      userRole,
      shareUserSig,
      roomInfos
    });
    if (action === 'createRoom' || StringUtils.isObjEqual(userRole,USER_ROLE_MAJOR)){
      await this.$refs.TUIRoomRef.createRoom(Number(this.roomId), roomMode, roomParam);
    } else if (action === 'enterRoom') {
      await this.$refs.TUIRoomRef.enterRoom(Number(this.roomId), roomParam);
    }
    // 关闭浏览器执行退出接口
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.addEventListener('beforeCreate', e => this.beforeunloadHandler(e))
    window.addEventListener('unload', e => this.unloadHandler(e))
  },
  methods: {
    // 处理用户点击页面左上角【退出登录】
    handleLogOut() {
      // 接入方处理 logout 方法
    },

    //退出房间
    stoproom(){
      let userid=JSON.parse(this.userInfo)
      stopRoom({roomNo:this.roomId,userId:userid.userId}).then(res=>{
        console.log(res)
      }).catch(err => {
            console.log(err)
        })
    },


    // 主持人创建房间回调
    onCreateRoom(info) {
      console.log("主持人创建房间回调 - ----------------------大大")
      console.debug('onEnterRoom:', info);
    },

    // 普通成员进入房间回调
    onEnterRoom(info) {
      console.debug('onCreateRoom:', info);
    },

    // 主持人销毁房间回调
    onDestroyRoom(info) {
      console.debug('onDestroyRoom:', info);

      this.$router.replace({ path: '/admin',query:{roomId:info.data.roomId, userId: info.data.userId} });
    },

    // 普通成员退出房间回调
    onExitRoom(info) {
      console.debug('onExitRoom:', info);
      this.$router.replace({ path: '/login' });
    },

    // 普通成员被主持人踢出房间
    onKickOff(info) {
      console.log('onKickOff:', info);
      this.$router.replace({ path: '/admin',query:{roomId:info.data.roomId, userId: info.data.userId} });
    },

    beforeunloadHandler(e) {
      // TUIRoomCore.manualDestroy();
      e = e || window.event;
      if (e) {
        e.returnValue = '关闭提示';
      }
      alert("您还未保存页面内容，是否离开当前页面");
      return '关闭提示';
    },
    // 关闭窗口之后执行--暂时用不到
    unloadHandler() {

    },
  },
  destroyed() {
    // 关闭浏览器执行退出接口
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e));
    window.removeEventListener('beforeCreate', e => this.beforeunloadHandler(e));
    window.removeEventListener('unload', e => this.unloadHandler(e))
  },
};
</script>

<style lang="scss" scoped>
#app{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #B3B8C8;
  width: 100%;
  height: 100%;
  user-select:text;
}
.box{
  width: 100%;
  height: 100%;
  background: url(@/assets/img/beijing.jpg);
  background-size: cover;
}
</style>
