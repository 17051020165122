const liveScene = {
    state: {
      data: [],
    },
    mutations: {
      //修改存储
      alter_liveScene(state, str) {
    
        let list = state.data.push(str);
        return Object.assign({}, state, { data: list });
      },
    },
    actions: {
      //修改
      call_liveScene(context, str) {
        context.commit('alter_liveScene', str);
      },
    },
    getters: {
      //获取
      get_liveScene(state) {
        return state.data;
      },
    },
  };
  export default liveScene;
  