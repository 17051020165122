import { defineStore } from 'pinia';

interface MessageItem {
  ID: string;
  type: string;
  avatar:string;
  clientTime:number;
  payload: {
    text: string;
  };
  nick: string;
  from: string;
  flow: string;
}

interface ChatState {
  messageList: MessageItem[];
  isMuteChatByMater: boolean;
}

export const useChatStore = defineStore('chat', {
  state: (): ChatState => ({
    messageList: [],
    isMuteChatByMater: false
  }),
  getters: {

  },
  actions: {
    updateMessageList(message: MessageItem) {

      //初始消息发送获取
      console.log('-------------updateMessageList----------------')
      console.log(this.messageList.concat([message]))
      this.messageList = this.messageList.concat([message]);
    },
    setIsMuteChatByMater(isMuteChatByMater: boolean) {
      this.isMuteChatByMater = isMuteChatByMater;
    },
    reset() {
      this.messageList = [];
    },
  },
  persist: {
    enabled: true,
    strategies: [{
      key: "chatStorage",
      storage: sessionStorage,//localStorage存储多个key
    },]
  },
});
