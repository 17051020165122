<template>
    <el-tabs v-model="currentName"  @tab-click="handleClick">
    <el-tab-pane v-for="(item,index) in roomlist" :key="index" :label="item.menuTitle" :name="item.menuTitle">
      <div v-if="item.menuTitle=='直播介绍' && $isMobile">
        <div style="display: flex;align-items: center;margin-bottom: 10px;border-bottom: 1px solid #ccc;">
          <img style="width: 65px;height: auto;margin-right: 20px;" :src="avatar" alt="">
          <div>
            <div style="font-size: 16px;" class="c-splash__header__title" :title="roomName" >{{ roomName }}</div>
            <div class="c-splash__header__status" style="font-size: 14px;margin: 10px 0;">
              直播时间：{{ startTime }}
            </div>
          </div>
          
        </div>
         <div v-html="item.menuContent">
        </div>
      </div>
      <div v-else>
        <div v-html="item.menuContent">
      </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="回放列表" class="playback" name="back">
       <div style="text-align: center;width: 180px;" v-for="(item,index) in playbacklists" :key="index" @click="playlist(item)">
          <img :src="item.cover!=null?item.cover:roomInfo.bgImg" alt="">
           <p style="margin: 0 auto;width: 90%;">{{item.playFileName}}</p>
       </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { Avatar, Tabs } from 'element-ui';
import { mapState } from 'vuex';
import {
  UPDATE_VIDEO_URL
} from '@/constants/mutation-types';
export default {
  name: 'index',
  props: {
    roomlist:{},
    playbacklists:{}
  },
  mixins: [],
    data() {
      return {
        currentName: 'back',
        roomName: '',
        startTime: '',
        avatar:''
      };
    },
    computed: {
      ...mapState({
        userInfo: 'userInfo',
        userSig: state => state.userInfo.userSig,
        sdkAppId: state => state.sdkAppId,
        shareUserSig: state => state.userInfo.shareUserSig,
        shareUserId: state => `share_${state.userInfo.userId}`,
        playerDomain: 'playerDomain',
        roomInfo: 'roomInfo',
        anchorUserInfo: 'anchorUserInfo',
        thidrdId:"thidrdId",
      }),
    },
    watch:{
      roomInfo: {
        immediate: true,
        handler(val) {
          this.roomName = val.roomName;
          this.startTime = val.startTime;
          this.avatar = val.logoCover[0];
        },
      },
    },
    async created(){
      console.log(11111,this.roomInfo);
     
      this.roomName = this.roomInfo.roomName;
      this.startTime = this.roomInfo.startTime;
      this.avatar = this.roomInfo.logoCover[0];
    },
    mounted(){
    
    },
    methods: {
      handleClick(tab, event) {
        // console.log(tab.index, event);
        // this.activeName=tab.index
      },
      playlist(val){
        
          //this.$bus.emit('parName',val.videoURL)
         this.$store.commit(UPDATE_VIDEO_URL,val.videoURL);
      } 
      
    }
  };
</script>

<style lang="less" scoped>
img{
    width: 160px;
    height: 100%;
    //margin: 15px 0;
}
// .c-splash__header__title{
//   overflow:hidden;
//     white-space:nowrap;
//     text-overflow:ellipsis;
//     -webkit-text-overflow:ellipsis;
// }
/deep/.el-tabs__item{
    font-size: 20px;
    /* font-weight: bolder; */
}
/deep/.el-tabs__nav{
    line-height: 55px;
}
/deep/.el-tab-pane{
  padding-bottom: 40px;
}
::v-deep .el-tabs__content img{
  max-width: 100%;
  height: auto;
  /* transform: scale(1); */
}
::v-deep .el-tabs__content span{
  display: inline-block;
}
::v-deep .el-tabs__content {
  p,div{
    white-space:normal;
    word-break:break-all;
    width: 100%;
  }
}

/deep/ .el-tabs__content{
  font-size: 0 !important;
  text-align: left;
  height: 65%;
  overflow: scroll;
}
/deep/ .el-tabs__content::-webkit-scrollbar {display:none}
.playback{
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 600px){
  /deep/ .el-tabs__item{
   font-size: 16px;
}
  }
@media screen and (max-width: 600px){
  /deep/ .el-tabs__nav-prev{
  line-height: 57px;
}
  }
  @media screen and (max-width: 600px){
  /deep/ .el-tabs__nav-next {
  line-height: 57px;
}
  }
  /* @media screen and (max-width: 600px){
  /deep/ .el-tab-pane {
  height: 24rem;
  overflow: scroll;
}
  } */

</style>
