const hotOnline = {
  state: {
    data: [],
  },
  mutations: {
    //修改存储
    alter_hotOnline(state, str) {
      let list = state.data.push(str);
      return Object.assign({}, state, { data: list });
    },
  },
  actions: {
    //修改
    call_hotOnline(context, str) {
      context.commit('alter_hotOnline', str);
    },
  },
  getters: {
    //获取
    get_hotOnline(state) {
      return state.data;
    },
  },
};
export default hotOnline;
