import $Api from "@/api";
import { Message } from "element-ui";
import { getRoomList,getRoodDetail } from "@/api/player/player";
import { mapState } from "vuex";
import StringUtils from "@/putils/StringUtils"
import StorageUtils from '@/putils/StorageUtils';
import * as mutationTypes from '@/constants/mutation-types';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      roomlist: "roomlist",
    }),
  },
  methods: {
    //获取菜单栏列表
    async getRoomlist() {
      let roomData=StorageUtils.getJsonSessionItem(mutationTypes.SET_ROOM_LIST);
      if(StringUtils.isEmpty(roomData)){
        return await this.setStateLiveRoomInfo()
      }
      return roomData;
    },
    async setStateLiveRoomInfo() {
      let dres= await new Promise((resolve, reject) => {
        getRoomList({ roomNo: this.$route.query.roomId })
        .then((res) => {
          if (res.code == "000") {
            resolve(res.data)
          }else{
            reject(null)
          }
        })
        .catch((err) => {
          console.log(err);
          reject(null);
        });
      });
      this.$store.commit(mutationTypes.SET_ROOM_LIST, dres);
      return dres;
    },

    async getRoodDetail(data) {
      let dres = await new Promise((resolve, reject) => {
        getRoodDetail(data)
          .then(res => {
            if (res.code == '000') {
              resolve(res.data)
            }else{
              console.log(res);
              reject(null);
            }
          }).catch(err=>{
            console.log(err);
          reject(null);
        });
      });
      return dres;
    }
  },
};
