<template>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="签到考试" name="second">
          <div>
              <p style="font-size: 14px;">签入时间： 2022年9月19号 09:00~11:00</p>
              <p style="font-size: 14px;">签出时间： 2022年9月19号 09:00~11:00</p>
          </div>
          <p style="display: flex;flex-direction: column;align-items: center;">
                  <img src="../../../assets/img/exam.jpg" @click="goexam" alt="">
              <img src="../../../assets/img/sign.jpg" @click="gosign" alt="">


          </p>
      </el-tab-pane>
      <el-tab-pane label="会议日程" name="third">会议日程</el-tab-pane>
      <el-tab-pane label="专家介绍" name="fourth">专家介绍</el-tab-pane>
  </el-tabs>
</template>

<script>
import { Tabs } from 'element-ui';
import { mapState } from 'vuex';
import { LAYOUT } from '@/constants/room';

export default {
    name:"footer-mobile",
    data() {
      return {
        activeName: 0,
        layout_horizontal:false
      };
    },
    computed: {
      ...mapState({
        userInfo: 'userInfo',
        userSig: state => state.userInfo.userSig,
        sdkAppId: state => state.sdkAppId,
        shareUserSig: state => state.userInfo.shareUserSig,
        shareUserId: state => `share_${state.userInfo.userId}`,
        playerDomain: 'playerDomain',
        roomInfo: 'roomInfo',
        anchorUserInfo: 'anchorUserInfo',
        thidrdId:"thidrdId",
        layout: 'layout',
      }),
    },
    created(){
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
      goexam(){
        window.location.href=`http://hy.cmnt.cn/live/sign?m_id=2239&channelId=10118&type=2&thirdId=${this.thirdId}`
      },
      gosign(){
        window.location.href=`http://hy.cmnt.cn/live/sign?m_id=2239&channelId=10118&type=1&thirdId=${this.thirdId}`
      }
    }
  };
</script>

<style scoped>
img{
    width: 50px;
    height: 100%;
    margin: 15px 0;
}
/deep/.el-tabs__item{
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}
/deep/.el-tabs__nav{
    line-height: 55px;
}
/deep/.el-tabs__content{
  right: 125px;
  top: 148px;
}
</style>
