import { render, staticRenderFns } from "./SettingControl.vue?vue&type=template&id=49729c04&scoped=true&"
import script from "./SettingControl.vue?vue&type=script&setup=true&lang=ts&"
export * from "./SettingControl.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./SettingControl.vue?vue&type=style&index=0&id=49729c04&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49729c04",
  null
  
)

export default component.exports